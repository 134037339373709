import keycloak from "../security/keycloak";
import { getSocket } from "../socket";

const apiCaller = store => next => action => {
    let result = next(action);
    const { socket } = getSocket();
    const state = store.getState();
    const code = state.auth.currentCode;
    const nickname = keycloak.tokenParsed?.preferred_username || state.auth.nickname || "anonymous";
    const password = state.auth.cachedPassword;
    const token = keycloak.token;
    //console.log({location: "apiCaller.js", action, token, keycloak});
    switch (action.type) {
        case "bag/updateName":
            if (socket !== null) {
                socket.emit("update_bag", {
                    code,
                    update: {
                        name: action.payload.name
                    },
                    nickname,
                    password,
                    token
                });
            } else {
                console.error("socket was null");
            }
            break;
        case "bag/removeItem":
            if (socket !== null) {
                socket.emit("remove_item", {
                    code,
                    id: action.payload.id,
                    nickname,
                    password,
                    token
                });
            } else {
                console.error("attempted bag/addItem emit but socket was null")
            }
            break;
        case "bag/addItem":
            if (socket !== null) {
                socket.emit("append_item", {
                    code,
                    item: action.payload.item,
                    nickname,
                    password,
                    token
                });
            } else {
                console.error("attempted bag/addItem emit but socket was null")
            }
            break;
        case "bag/updateItem":
            if (socket !== null) {
                socket.emit("update_item", {
                    code,
                    id: action.payload.id,
                    item: action.payload.item,
                    nickname,
                    password,
                    token
                });
            }
            break;
        default:
            break;
    }
    return result;
}

export default apiCaller;