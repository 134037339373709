import store from "./app/store";
import socketIOClient from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { setBag, setPending, updateError, updatePasswordRequired } from "./features/bag/bagSlice";
import { addToast } from "./features/toasts/toastsSlice";
import { setCurrentCode } from "./features/auth/authSlice";
import { WEBSOCKET_HOST } from "./config";
import { BAG } from "./utils/models";

let socket = null;

const addPreviousBag = (code, name) => {
    const prevBags = JSON.parse(localStorage.getItem("prevBags"));
    if (prevBags === null) {
        localStorage.setItem("prevBags", JSON.stringify([{
            code, name
        }]));
    } else {
        const existingBagIndex = prevBags.findIndex(b => b.code.toUpperCase() === code.toUpperCase())
        if (existingBagIndex === -1) {
            localStorage.setItem("prevBags", JSON.stringify([
                ...prevBags,
                { code, name }
            ]));
        } else if (prevBags[existingBagIndex].name !== name) {
            prevBags[existingBagIndex].name = name;
            localStorage.setItem("prevBags", JSON.stringify(prevBags))
        }
    }
};

export const createSocket = () => {
    socket = socketIOClient(WEBSOCKET_HOST);
    socket.on("connection", socket => {
        /* pass */
    });
    socket.on("newBag", (code) => {
        store.dispatch(setCurrentCode({ currentCode: code }));
    })
    socket.on("bag", data => {
        store.dispatch(setBag({ bag: data }));
        store.dispatch(setPending({ pending: false }));
        addPreviousBag(store.getState().auth.currentCode, data[BAG.bag_details.value]?.name);
    });
    socket.on("bad_password", data => {
        store.dispatch(setPending({ pending: false }));
        store.dispatch(updateError({ error: data.error }));
        store.dispatch(updatePasswordRequired({ passwordRequired: true }));
    });
    socket.on("bad_permissions", data => {
        //store.dispatch(setBag({ bag: null }));
        store.dispatch(setPending({ pending: false }));
        store.dispatch(updateError({ error: data.error }));
        //alert(`There was an error:\n${data.error}`)
        store.dispatch(addToast({ toast: { key: uuidv4(), header: "Error", content: data.error, type: "error" } }))
    })
    socket.on("error", data => {
        //store.dispatch(setBag({ bag: null }));
        store.dispatch(setPending({ pending: false }));
        store.dispatch(updateError({ error: data.error }));
        //alert(`There was an error:\n${data.error}`)
        store.dispatch(addToast({ toast: { key: uuidv4(), header: "Error", content: data.error, type: "error" } }))
    })
    socket.on("joined", joinedId => {
        /* pass */
    });
    socket.on("toast", toast => {
        store.dispatch(addToast({ toast }))
    })
}

export const createOrGetSocket = () => {
    if (socket !== null) return { socket };
    createSocket();
    return { socket };
}
export const getSocket = () => {
    return { socket };
};