import BSSpinner from "react-bootstrap/Spinner"


const Spinner = ({animation="border", size="sm"}) => {
    return (
    <BSSpinner animation={animation} role="status" size={size}>
        <span className="visually-hidden">Loading...</span>
    </BSSpinner>
    )
};

export default Spinner;