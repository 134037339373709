import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Editable = ({ children }) => {
    const item = children;
    return (
        <Row>
            <Col xs={0} sm={1}/>
            <Col xs="auto" className="fw-bold">
                <Row>
                    <div className="text-end">
                        Name
                    </div>
                </Row>
                <Row>
                    <div className="text-end">
                        Quantity
                    </div>
                </Row>
                <Row>
                    <div className="text-end">
                        Description
                    </div>
                </Row>
            </Col>
            <Col>
                <Row><div className="text-break">{item.name}</div></Row>
                <Row><div className="text-break">{item.quantity}</div></Row>
                <Row>
                    {item.description.split("\n").map(i =>
                        <div className="text-break" key={i}>
                            {i}
                        </div>
                    )}
                </Row>
            </Col>
        </Row>
    )
};

export default Editable;