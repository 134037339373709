import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClearIcon from "@mui/icons-material/DeleteSweep";
import { setCurrentCode } from "../features/auth/authSlice";


const RecentBags = () => {
    const [bags, setBags] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const _bags = localStorage.getItem("prevBags");
        try {
            setBags(JSON.parse(_bags));
        } catch {
            setBags(null);
        }
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <h5>Recent Bags</h5>
                </Col>
                <Col xs="auto" role="button" className="text-muted" onClick={()=>{
                    localStorage.setItem("prevBags", JSON.stringify([]))
                    setBags([]);
                }}>

                    <ClearIcon className="hover-delete"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup style={{ maxHeight: "50vh", overflowY: "auto" }}>
                        {bags === null || bags.length === 0 ?
                            <span className="text-muted">
                                You have no recent bags.
                            </span>
                            :
                            bags.map(bag =>
                                <ListGroup.Item key={bag.code}>
                                    <Row>
                                        <Col className="align-middle">
                                            {bag.code} - {bag.name}
                                        </Col>
                                        <Col>
                                            <div className="text-end">
                                                <Button onClick={() => {
                                                    dispatch(setCurrentCode({ currentCode: bag.code }));
                                                }}>Connect</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>)
                        }

                    </ListGroup>
                </Col>
            </Row>
        </>
    );
}

export default RecentBags;