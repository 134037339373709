import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    user: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
        }
    }
});

export const {
    setUser,
} = userSlice.actions;

export const selectUser = state => state.user.user;

export default userSlice.reducer;