import { useState } from "react";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCode } from "../features/auth/authSlice";
import { selectUser } from "../features/user/userSlice";
import api from "../utils/api";
import { BAG, BAG_ACCESS, BAG_DETAILS } from "../utils/models";
import { PermissionsTuple } from "./PermissionsTuple";

const CreateBag = ({ showAnonPerms, showSignedIn, showHavePassword, onCreateBag }) => {
    const dispatch = useDispatch();

    const token = useSelector(selectUser)?.token;

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [currentlyFocused, setCurrentlyFocused] = useState(false);
    const [focused, setFocused] = useState(false);
    const [pending, setPending] = useState(false);

    const [NONE, READ, WRITE] = [0, 1, 2];
    const [anonPerms, setAnonPerms] = useState(WRITE);
    const [signedInPerms, setSignedInPerms] = useState(WRITE);
    const [havePasswordPerms, setHavePasswordPerms] = useState(WRITE);
    /*permissions[BAG_ACCESS.allow_authorised_write.value]
                              ? WRITE
                              : permissions[BAG_ACCESS.allow_authorised_read.value]
                                ? READ
                                : NONE;*/


    const nameValid = BAG_DETAILS.name.validator(name);
    // if it's not in focus, it can't be invalid
    const nameFieldValid = (!currentlyFocused) || nameValid;

    const passwordValid = BAG_ACCESS.password.validator(password);
    const passwordsMatch = password.length === 0 || (password === cPassword);

    // it must have been focused in the past to be valid
    const formValid = focused && nameValid && passwordValid && passwordsMatch;

    const usePassword = password.length > 0;

    const handleSubmit = e => {
        e.preventDefault();
        setPending(true);
        api.bag.create(
            name,
            usePassword ? password : null,
            anonPerms >= READ,
            anonPerms >= WRITE,
            signedInPerms >= READ,
            signedInPerms >= WRITE,
            havePasswordPerms >= READ,
            havePasswordPerms >= WRITE,
            token
        ).then(({ [BAG.code.value]: currentCode }) => {
            setPending(false);
            dispatch(setCurrentCode({ currentCode }));
            if (onCreateBag) {
                onCreateBag(currentCode);
            }
        }).catch(error => {
            // todo: handle this
            console.error(error);
            setPending(false);
        })
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <fieldset disabled={!!pending}>
                    <Form.Group className="text-center fw-bold">
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={e => {
                                setName(e.target.value);
                            }}
                            isInvalid={!nameFieldValid}
                            value={name}
                            onFocus={() => { setFocused(true); setCurrentlyFocused(true) }}
                            onBlur={() => { setCurrentlyFocused(false) }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {BAG_DETAILS.name.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="text-center fw-bold pt-2">
                        <Form.Label>
                            Password (optional)
                        </Form.Label>
                        <Form.Control
                            type="password"
                            isInvalid={!passwordValid}
                            onChange={e => {
                                setPassword(e.target.value);
                                if (e.target.value.length > 0) {
                                    setAnonPerms(NONE);
                                    setSignedInPerms(NONE);
                                } else if (e.target.value.length === 0) {
                                    setAnonPerms(WRITE);
                                    setSignedInPerms(WRITE);
                                }
                            }}
                            value={password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {BAG_ACCESS.password.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {password && password.length > 0 &&
                        <Form.Group className="text-center fw-bold pt-1">
                            <Form.Label>
                                Confirm Password
                            </Form.Label>
                            <Form.Control
                                type="password"
                                isInvalid={!passwordsMatch}
                                onChange={e => {
                                    setCPassword(e.target.value);
                                }}
                                value={cPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                                Your passwords do not match!
                            </Form.Control.Feedback>
                        </Form.Group>
                    }
                    {(showAnonPerms || showSignedIn || (usePassword && showHavePassword)) && <hr />}
                    {showAnonPerms &&
                        <PermissionsTuple
                            name="Anonymous user permissions"
                            value={anonPerms}
                            values={["None", "Read", "Write"]}
                            onChange={setAnonPerms}
                        />
                    }
                    {showSignedIn &&
                        <PermissionsTuple
                            name="Signed in user permissions"
                            value={signedInPerms}
                            values={["None", "Read", "Write"]}
                            onChange={setSignedInPerms}
                        />
                    }
                    {usePassword && showHavePassword &&
                        <PermissionsTuple
                            name="Permissions for users with password"
                            value={havePasswordPerms}
                            values={["None", "Read", "Write"]}
                            onChange={setHavePasswordPerms}
                        />
                    }
                    <hr />
                    <div className="text-center">
                        <Button
                            type="submit"
                            disabled={!formValid}
                        >
                            {pending ? <>Loading</> : "Create Bag"}
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </>
    );
}

export default CreateBag;