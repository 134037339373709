import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";

import CreateBag from "./CreateBag";
import { setCurrentCode } from "../features/auth/authSlice";
import { BAG } from "../utils/models";
import { useKeycloak } from "@react-keycloak/web";

const ConnectToBag = () => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const [code, setCode] = useState("");
    const [showCreateModal, setShowCreateModal] = useState(false);

    const codeValid = BAG.code.validator(code);
    const displayCodeInvalid = !codeValid && code.length > 0;

    const { keycloak } = useKeycloak();
    const isSignedIn = keycloak.authenticated;

    return (
        <>
            <Form onSubmit={e => {
                dispatch(setCurrentCode({ currentCode: code.toUpperCase() }));
            }}>
                <Row>
                    <Col>
                        <Row className="text-center">
                            <Form.Label>
                                Connect To Bag
                            </Form.Label>
                        </Row>
                        {pending ?
                            <div className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <Row>
                                    <Col xs="12" md className="py-1">
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Bag Code"
                                                className="fs-4"
                                                onChange={e => {
                                                    setCode(e.target.value);
                                                }}
                                                isInvalid={displayCodeInvalid}
                                                value={code}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {BAG.code.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs md={"auto"} >
                                        <div className="d-flex justify-content-center align-items-start">
                                            <Button
                                                type="submit"
                                                disabled={code.length === 0}
                                                className="fs-4 mt-2"
                                            >
                                                Connect
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-center align-items-start mb-3">
                                            <Button
                                                variant="success"
                                                className="fs-4 mt-2 px-5 py-3"
                                                onClick={() => setShowCreateModal(true)}
                                            >
                                                Create New Bag
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
            </Form>

            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Bag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateBag showAnonPerms={isSignedIn} showSignedIn={isSignedIn} showHavePassword={isSignedIn} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ConnectToBag;