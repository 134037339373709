import { useState } from "react"

export const useControl = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);
    const handleOnChange = e => {
        setValue(e.target.value);
    }
    return [value, handleOnChange, setValue];
};

export const useFocus = () => {
    const [focused, setFocused] = useState(false);
    const [everFocused, setEverFocused] = useState(false);
    const handleOnFocus = () => {
        setFocused(true);
        setEverFocused(true);
    };

    const handleOnBlur = () => {
        setFocused(false);
    };

    const handleResetFocus = () => {
        setFocused(false);
        setEverFocused(false);
    };
    return [focused, everFocused, handleOnFocus, handleOnBlur, handleResetFocus];
}