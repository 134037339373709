import { useDispatch } from "react-redux";

import { removeItem } from "./bagSlice";
//import Editable from "../../components/Editable";
import Editable from "../editable/Editable";

import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { setEditItem, setModalOpen } from "../editable/editableSlice";

export const ItemElement = ({ children }) => {
    const dispatch = useDispatch();
    const item = children;

    return (
        <Accordion.Item
            eventKey={item.name}
        >
            <Accordion.Header>
                <Container>
                    <Row>
                        <Col className="gx-1 text-truncate" style={{maxWidth: "70vw"}}>{item.name}</Col>
                        <Col xs="auto" onClick={e => {
                            e.stopPropagation();
                            if (window.confirm(`This will delete ${item.name} permanently. Are you sure?`)) {
                                dispatch(removeItem({ id: item["_id"] }));
                            }
                        }} className="remove-icon gx-1"><DeleteIcon className="hover-delete"/></Col>
                        <Col className="gx-1" xs="auto" onClick={e => {
                            e.stopPropagation();
                            dispatch(setEditItem({ item }));
                            dispatch(setModalOpen({ open: true }));
                        }}>
                            <EditIcon className="hover-edit" />
                        </Col>
                    </Row>
                </Container>
            </Accordion.Header>
            <Accordion.Body >
                <Editable>{item}</Editable>
            </Accordion.Body>
        </Accordion.Item>
    )
}

const ItemList = ({ children, Element }) => {
    return (
        <>
            <Accordion className="item-list" style={{ width: "100%", maxHeight: "50vh", overflowY: "auto" }}>
                {children.length === 0 ?
                    <span className="text-muted">No Items!</span>
                    :
                    <>{
                        children.map(item => <Element key={item.name}>{item}</Element>)
                    }</>
                }
            </Accordion>
        </>
    );
}

export default ItemList;