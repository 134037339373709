// This file transcribed from neoextradimesnional/websocket

import { API_HOST } from "../config";
import keycloak from "../security/keycloak";
import { BAG_ACCESS, BAG_DETAILS, USER } from "./models";

const makeInit = (init, method, password) => {
    const _init = { ...init, method, headers: { ...init.headers } };
    const token = keycloak["token"];
    //console.log({location: "api.js/makeInit", keycloak, token})
    //console.log({location: "api,sj/makeInit", kc: JSON.stringify(keycloak)})
    if (token) {
        _init.headers["Authorization"] = `Bearer ${token}`;
    }
    if (password) {
        _init.headers["X-Password"] = password;
    }
    if (["POST", "PUT"].includes(method) && init.body) {
        _init.headers["Content-Type"] = "application/json";
    }
    if (init.body) {
        _init.body = JSON.stringify(init.body);
    }
    return _init;
}

const _fetch = (url, init) => {
    return fetch(url, init).then(async res => {
        if (res.status >= 200 && res.status < 300) {
            try {
                const text = await res.text();
                if (text) {
                    const json = JSON.parse(text);
                    return Promise.resolve(json);
                }
                return Promise.resolve({});
            } catch (e) {
                return Promise.reject({ status: res.status, error: { message: "Failed to parse response. Please contact support." } })
            }
        } else {
            let error = null;
            try {
                error = await res.text();
            } catch (e) {
                console.error(e);
                error = "Unspecified Error";
            }
            try {
                const _error = JSON.parse(error);
                error = _error;
            } catch (e) {
                // do nothing
                error = { "message": error };
            }
            return Promise.reject({ status: res.status, error })
        }
    });
};

const request = {
    get: (endpoint, init, password) => _fetch(API_HOST + endpoint, makeInit(init, "GET", password)),
    post: (endpoint, init, password) => _fetch(API_HOST + endpoint, makeInit(init, "POST", password)),
    put: (endpoint, init, password) => _fetch(API_HOST + endpoint, makeInit(init, "PUT", password)),
    delete: (endpoint, init, password) => _fetch(API_HOST + endpoint, makeInit(init, "DELETE", password)),
};

const api = {
    bag: {
        create: (
            name,
            password,
            allow_anon_read,
            allow_anon_write,
            allow_read,
            allow_write,
            allow_authorised_read,
            allow_authorised_write,
        ) => request.post("/bags", {
            body: {
                [BAG_DETAILS.name.value]: name,
                [BAG_ACCESS.password.value]: password,
                [BAG_ACCESS.allow_anon_read.value]: allow_anon_read,
                [BAG_ACCESS.allow_anon_write.value]: allow_anon_write,
                [BAG_ACCESS.allow_read.value]: allow_read,
                [BAG_ACCESS.allow_write.value]: allow_write,
                [BAG_ACCESS.allow_authorised_read.value]: allow_authorised_read,
                [BAG_ACCESS.allow_authorised_write.value]: allow_authorised_write
            }
        }),
        delete: (code) => request.delete(`/bags/${encodeURIComponent(code)}`, {}),
        edit: (
            name,
            password,
            allow_anon_read,
            allow_anon_write,
            allow_read,
            allow_write,
            allow_authorised_read,
            allow_authorised_write,
        ) => request.put("/bags", {
            body: {
                [BAG_DETAILS.name.value]: name,
                [BAG_ACCESS.password.value]: password,
                [BAG_ACCESS.allow_anon_read.value]: allow_anon_read,
                [BAG_ACCESS.allow_anon_write.value]: allow_anon_write,
                [BAG_ACCESS.allow_read.value]: allow_read,
                [BAG_ACCESS.allow_write.value]: allow_write,
                [BAG_ACCESS.allow_authorised_read.value]: allow_authorised_read,
                [BAG_ACCESS.allow_authorised_write.value]: allow_authorised_write
            }
        }),
    },
    user: {
        me: () => request.get("/user/me", {}),
        subscription: (subscribed) => request.post("/user/me/subscribed", { body: {
            [USER.subscribed.value]: subscribed
        }})
    }
};

export default api;