import { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
    setPending,
    selectBagPending,
    selectBag,
    selectError,
    selectPasswordRequired,
    resetBag
} from "./bagSlice";

import SettingsIcon from "@mui/icons-material/Settings";
import EyeIcon from "@mui/icons-material/Visibility";
//import EyeOffIcon from "@mui/icons-material/VisibilityOff";
import EyeOutlineIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlineIcon from "@mui/icons-material/EditOutlined";
import KeyIcon from "@mui/icons-material/VpnKey";
import KeyOutlineIcon from "@mui/icons-material/VpnKeyOutlined"
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import ListQuery from "./ListQuery";
import ItemList, { ItemElement } from "./ItemList";
import AddItem from "./AddItem";
import EditableModal from "../editable/EditableModal";
import { createOrGetSocket } from "../../socket";
import { selectCachedPassword, selectNickname, setCachedPassword, setCurrentCode } from "../auth/authSlice";
import { BAG, BAG_ACCESS, BAG_DETAILS } from "../../utils/models";
import keycloak from "../../security/keycloak";

const EnterPassword = ({ title = "This bag requires a password to access.", passwordIncorrect = false }) => {
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    return (
        <Form onSubmit={e => {
            e.preventDefault();
            dispatch(setCachedPassword({ cachedPassword: password }));
        }}>
            <Row className="text-center">
                <Form.Label>
                    {title}
                </Form.Label>
            </Row>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Control
                            placeholder="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto">
                    <Form.Group>
                        <Button type="submit" disabled={password.length === 0}>Enter</Button>
                    </Form.Group>
                </Col>
            </Row>
            {passwordIncorrect && <Row>
                <Col className="text-center text-danger">
                    That password was incorrect. Please try again.
                </Col>
            </Row>}
        </Form>
    );
};

const Permissions = ({ access }) => {
    const permissionMap = {
        "none": {
            "read": false,
            "write": false,
            "owner": false
        },
        "read": {
            "read": true,
            "write": false,
            "owner": false
        },
        "write": {
            "read": true,
            "write": true,
            "owner": false
        },
        "owner": {
            "read": true,
            "write": true,
            "owner": true
        }
    };
    const { read, write, owner } = (permissionMap[access] || {});
    return <span title={`Access: ${access}`}>
        {read
            ? <EyeIcon />
            : <EyeOutlineIcon className="text-muted" />}
        {write
            ? <EditIcon />
            : <EditOutlineIcon className="text-muted" />}
        {owner
            ? <KeyIcon />
            : <KeyOutlineIcon className="text-muted" />}
    </span>
}

const Bag = () => {
    const { code } = useParams();
    const dispatch = useDispatch();
    const fullBag = useSelector(selectBag);
    const bag = fullBag && fullBag[BAG.bag_details.value];
    const bagAccess = fullBag && fullBag[BAG.bag_access.value];
    const access = bagAccess && bagAccess[BAG_ACCESS.access.value];
    const accessIndex = BAG_ACCESS.ACCESS_NAMES.indexOf(access);
    const passwordAvailable = bagAccess && bagAccess[BAG_ACCESS.password_available.value];
    const pending = useSelector(selectBagPending);
    const bagError = useSelector(selectError);
    const nickname = useSelector(selectNickname);
    const bagPassword = useSelector(selectCachedPassword);
    const passwordRequired = useSelector(selectPasswordRequired);
    const [showCopyTip, setShowCopyTip] = useState(false);
    const [copied, setCopied] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [canShare, setCanShare] = useState(false);
    const token = keycloak.token;
    const keycloakUsername = keycloak.tokenParsed?.preferred_username;

    const shareData = useMemo(() => {
        return {
            url: `https://extradimension.al/bag/${code}?ref=1`,
            text: `Access bag ${bag?.name || "Unamed Bag"} on extradimension.al`,
            title: "A bag of holding has been shared with you on extradimension.al"
        }
    }, [code, bag]);

    useEffect(() => {
        if (navigator && navigator.canShare && navigator.share && navigator.canShare(shareData)) {
            setCanShare(true);
        } else {
            setCanShare(false);
        }
    }, [shareData])

    useEffect(() => {
        const { socket } = createOrGetSocket();
        const _nickname = keycloakUsername || nickname || "anonymous";
        //console.log({ location: "Bag.js/useEffect/join_bag", code, nickname: _nickname, bagPassword, token });
        socket.emit("join_bag", { code, nickname: _nickname, password: bagPassword, token });
        dispatch(setPending({ pending: true }));
        dispatch(setCurrentCode({ currentCode: code }));
        // we only want to emit join_bag here if the code itself is update, not the nickname
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, bagPassword, token]);

    useEffect(() => {
        // if just copied, create a delay to unset it
        if (copied) {
            const i = setInterval(() => {
                setCopied(false);
            }, 2000)
            return () => {
                clearInterval(i);
            }
        }
    }, [copied])

    return (
        <>
            <Helmet>
                <title>Bag {bag && bag.name ? bag.name : code}</title>
            </Helmet>
            <Row>
                <Col xs="12" md>
                    {bag && <h3>{bag.name || "Unnamed bag"}</h3>}
                </Col>
                <Col xs md="auto" className="fs-3 d-flex align-items-center">
                    <OverlayTrigger
                        overlay={<Tooltip id="bag-code-tooltip">
                            Share this code with others so they can access this bag
                        </Tooltip>}
                    >
                        <span>{code}</span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        show={showCopyTip || copied}
                        onToggle={t => {
                            setShowCopyTip(t)
                        }}
                        overlay={<Tooltip id="bag-code-copy-tooltip">
                            {copied ? "Copied" : "Copy Bag Link"}
                        </Tooltip>}
                    >
                        {canShare
                            ? <ShareIcon
                                fontSize="small"
                                className="hover-info ms-1"
                                role="button"
                                aria-label="Share Bag"
                                onClick={() => {
                                    if (navigator && navigator.canShare && navigator.share && navigator.canShare(shareData)) {
                                        navigator.share(shareData).then(e => {
                                            //console.log({ e });
                                        }).catch(e => {
                                            console.error({ e });
                                        })
                                    } else {
                                        //console.log({ navigator })
                                    }
                                }}
                            />
                            // can't share: use clipboard
                            : <CopyToClipboard
                                text={`https://extradimension.al/bag/${code}?ref=2`}
                                onCopy={() => {
                                    setCopied(true);
                                }}
                            >
                                <ContentCopyIcon
                                    fontSize="small"
                                    className="hover-info ms-1"
                                    role="button"
                                    aria-label="Copy Bag Link"
                                />
                            </CopyToClipboard>

                        }
                    </OverlayTrigger>
                </Col>
                {!passwordAvailable && bagPassword &&
                    <Col xs="4" md="2"
                        className="d-flex align-items-center"
                        role="button" onClick={() => setViewPassword(!viewPassword)}
                        title={`${viewPassword ? "Hide" : "Reveal"} bag password`}
                    >
                        Password: {' '}
                        <span className="flex-grow-1 d-flex justify-content-evenly">
                            {viewPassword ? <>{bagPassword}</> : "······".split("").map(i => <span>{i}</span>)}
                        </span>
                    </Col>
                }
                <Col xs="auto" className="d-flex align-items-center">
                    <Permissions access={bagAccess && bagAccess[BAG_ACCESS.access.value]} />
                </Col>
                {accessIndex >= 4 && bag &&
                    <Col xs="auto">
                        <Link to={`/bag/${code}/settings`}><SettingsIcon /></Link>
                    </Col>
                }
                {passwordAvailable && accessIndex < 3 /* less than write perms */ &&
                    <Col xs="12" className="pb-3">
                        <EnterPassword title="For extra access, please enter the password" passwordIncorrect={passwordAvailable && bagPassword} />
                    </Col>
                }
            </Row>
            {!bag
                ? pending === true
                    ? <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status" size="lg">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    : passwordRequired
                        ? <EnterPassword />
                        : <p>
                            {bagError !== null
                                ? <>There was an error: {bagError}</>
                                : <>There was an error but no error was set!</>
                            }
                            <Link to="/"
                                onClick={e => {
                                    dispatch(resetBag());
                                    dispatch(setCurrentCode({ currentCode: null }));
                                    dispatch(setCachedPassword({ cachedPassword: null }));
                                }}
                            >
                                Return Home
                            </Link>
                        </p>
                : <>
                    <Row>
                        <ListQuery items={bag[BAG_DETAILS.items.value]}>
                            <ItemList Element={ItemElement} />
                        </ListQuery>

                    </Row>
                    {accessIndex > 2 && <>
                        <hr />
                        <AddItem />
                    </>
                    }

                </>}
            <EditableModal />
        </>
    );
}

export default Bag;