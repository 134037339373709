import './App.scss';
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Bag from "./features/bag/Bag";
import BagSettings from "./features/bag/BagSettings";

import Container from "react-bootstrap/Container";
import { useSelector } from 'react-redux';
import { selectCurrentCode } from './features/auth/authSlice';
import TopNav from './components/TopNav';
import Toasts from "./features/toasts/Toasts";
import About from "./pages/About";
//import Legal from './pages/Legal';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Feedback from './pages/Feedback';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Me from './pages/Me';
import CreateBagLanding from "./pages/CreateBagLanding";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './security/keycloak';
import Spinner from './components/Spinner';

function App() {
  const currentCode = useSelector(selectCurrentCode);
  const Legal = React.lazy(() => import("./pages/Legal"));
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <Router>
        <TopNav />
        <Suspense fallback={<div className="text-center"><Spinner /></div>}>
          <Container>
            <Switch>
              <Route path="/create-bag">
                <CreateBagLanding />
              </Route>
              <Route path="/bag/:code/settings">
                <BagSettings />
              </Route>
              <Route path="/bag/:code">
                <Bag />
              </Route>
              <Route path="/me">
                <Me />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/legal">
                <Legal />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/feedback">
                <Feedback />
              </Route>
              <Route exact path="/">
                {currentCode === null ?
                  <Home />
                  :
                  <Redirect to={`/bag/${currentCode}`} />
                }
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
            <Toasts />
          </Container>
          <Footer />
        </Suspense>
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;
