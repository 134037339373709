import Form from "react-bootstrap/Form";

export const PermissionsTuple = ({value, onChange, name, values, tooltip}) => {

    return (
        <div className="pb-2">
            <div className="fw-bold text-center">{name}</div>
            <div className=" text-center">
                {values.map((v, i) => 
                    <Form.Check
                        inline
                        type="radio"
                        label={v}
                        key={i}
                        checked={i===value}
                        onChange={e=>{
                            onChange(i);
                        }}
                        id={`${name}-${v}`}
                    />
                )}
            </div>
        </div>
    );
}