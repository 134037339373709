import ConnectToBag from "../components/ConnectToBag";
import RecentBags from "../components/RecentBags";

const Home = () => {
    return (
        <>
            <ConnectToBag />
            <hr />
            <RecentBags />
        </>
    )
};

export default Home;