export const USER = {
    id: {
        value: "_id"
    },
    username: {
        value: "username",
        validator: v => /^[A-Za-z0-9-_.]{3,32}$/.test(v),
        error: "Username must contain only alphanumerics and the chatacters -_. and contain 3 to 32 characters",
    },
    password: {
        value: "password",
        validator: v => /^.{8,1000}$/.test(v),
        error: "Password must contain at least 8 characters"

    },
    email: {
        value: "email",
        validator: v => /^.{1,64}@.{1,255}$/.test(v),
        error: "Must be a valid email"
    },
    subscribed: {
        value: "subscribed",
        vaidator: () => true,
        error: ""
    },
    locked_until: {
        value: "locked_until"
    },
    owned_bags: {
        value: "owned_bags"
    },
    viewed_bags: {
        value: "viewed_bags"
    },
    email_verified: {
        value: "email_verified"
    },
    token: {
        value: "token"
    },
    password_verification_token: {
        value: "password_verification_token"
    },
    email_verification_token: {
        value: "email_verification_token"
    }
};

export const BAG = {
    id: {
        value: "_id"
    },
    code: {
        value: "code",
        validator: v => /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/.test(v),
        error: "Codes are in the form XXXX-XXXX"
    },
    bag_details: {
        value: "bag"
    },
    bag_meta: {
        value: "meta"
    },
    bag_access: {
        value: "access"
    }
};

export const BAG_ACCESS = {
    password: {
        value: "password",
        validator: v => v === null || /^.{0,1000}$/.test(v),
        error: "Bag password must be no longer than 1000 characters",
    },
    owner: {
        value: "owner"
    },
    allow_anon_read: {
        value: "allow_anon_read",
        validator: () => true,
        error: ""
    },
    allow_anon_write: {
        value: "allow_anon_write",
        validator: () => true,
        error: ""
    },
    allow_read: {
        value: "allow_read",
        validator: () => true,
        error: ""
    },
    allow_write: {
        value: "allow_write",
        validator: () => true,
        error: ""
    },
    allow_authorised_read: {
        value: "allow_authorised_read",
        validator: () => true,
        error: ""
    },
    allow_authorised_write: {
        value: "allow_authorised_write",
        validator: () => true,
        error: ""
    },
    authorised_readers: {
        value: "authorised_readers"
    },
    authorised_writers: {
        value: "authorised_writers",
    },
    access: {
        value: "access"
    },
    password_available: {
        value: "password_available"
    },
    ACCESS_NAMES: ["none", "unauthorised", "read", "write", "owner"]
};

export const BAG_DETAILS = {
    name: {
        value: "name",
        validator: v => /^.{1,100}$/.test(v),
        error: "Bag name must have between 1 and 100 characters",
    },
    items: {
        value: "items"
    }
};

export const BAG_META = {
    history: {
        value: "history"
    },
    creation_date: {
        value: "creation_date"
    },
    last_updated: {
        value: "last_updated"
    }
};

export const ITEM = {
    name: {
        value: "name",
        validator: v => /^.{1,100}$/.test(v),
        error: "Item name must be between 1 and 100 characters",
    },
    description: {
        value: "description",
        validator: v => v === null || /^.{0,1000}$/.test(v),
        error: "Item description must be between 1 and 1000 characters",
    },
    quantity: {
        value: "quantity",
        validator: v => typeof v === "number",
        error: "Quantity must be a number"
    }
}