import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CreateBag from "../components/CreateBag";
import { useHistory } from "react-router-dom";

const CreateBagLanding = () => {
    const history = useHistory();
    return (
        <>
            <Row>
                <Col xs="0" md="2" xl="3" />
                <Col xs="12" md="8" xl="6">
                    <h2>Welcome to Extradimensional</h2>
                </Col>
                <Col xs="0" md="2" xl="3" />
            </Row>
            <Row>
                <Col xs="0" md="2" xl="3" />
                <Col xs="12" md="8" xl="6">
                    Get started by creating a bag below. You can give it a name and we'll generate a code so you can share it with your party members. If you'd like you can give your bag a password too for extra security!
                </Col>
                <Col xs="0" md="2" xl="3" />
            </Row>
            <Row className="pt-3">
                <Col xs="0" md="2" xl="3" />
                <Col xs="12" md="8" xl="6">
                    <CreateBag onCreateBag={code => {
                        history.push(`/bag/${code}`)
                    }} />
                </Col>
                <Col xs="0" md="2" xl="3" />
            </Row >
        </>
    );
};

export default CreateBagLanding;