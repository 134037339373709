const monsters = [
    "Aboleth",
    "Acolyte",
    "Ankheg",
    "Ape",
    "Archmage",
    "Assassin",
    "Axe Beak",
    "Azer",
    "Baboon",
    "Badger",
    "Balor",
    "Bandit",
    "Banshee",
    "Basilisk",
    "Bat",
    "Behir",
    "Boar",
    "Bugbear",
    "Bulette",
    "Camel",
    "Cat",
    "Centaur",
    "Chimera",
    "Chuul",
    "Cloaker",
    "Commoner",
    "Couatl",
    "Crab",
    "Cultist",
    "Cyclops",
    "Deer",
    "Deva",
    "Djinni",
    "Dretch",
    "Drider",
    "Drow",
    "Druid",
    "Dryad",
    "Duergar",
    "Eagle",
    "Efreeti",
    "Elephant",
    "Elk",
    "Erinyes",
    "Ettercap",
    "Ettin",
    "Frog",
    "Gargoyle",
    "Ghast",
    "Ghost",
    "Ghoul",
    "Glabrezu",
    "Gnoll",
    "Goat",
    "Goblin",
    "Gorgon",
    "Grick",
    "Griffon",
    "Grimlock",
    "Guard",
    "Harpy",
    "Hawk",
    "Hezrou",
    "Hydra",
    "Hyena",
    "Imp",
    "Jackal",
    "Knight",
    "Kobold",
    "Kraken",
    "Lamia",
    "Lemure",
    "Lich",
    "Lion",
    "Lizard",
    "Mage",
    "Magmin",
    "Mammoth",
    "Marilith",
    "Mastiff",
    "Medusa",
    "Merfolk",
    "Merrow",
    "Mimic",
    "Minotaur",
    "Mule",
    "Mummy",
    "Noble",
    "Nothic",
    "Octopus",
    "Ogre",
    "Oni",
    "Orc",
    "Otyugh",
    "Owl",
    "Owlbear",
    "Panther",
    "Pegasus",
    "Planetar",
    "Pony",
    "Priest",
    "Quasit",
    "Quipper",
    "Rakshasa",
    "Rat",
    "Raven",
    "Remorhaz",
    "Roc",
    "Roper",
    "Sahuagin",
    "Satyr",
    "Scorpion",
    "Scout",
    "Sea Hag",
    "Shadow",
    "Shrieker",
    "Skeleton",
    "Solar",
    "Specter",
    "Spider",
    "Sprite",
    "Spy",
    "Stirge",
    "Thug",
    "Tiger",
    "Treant",
    "Troll",
    "Unicorn",
    "Vampire",
    "Veteran",
    "Vrock",
    "Vulture",
    "Warhorse",
    "Weasel",
    "Werebear",
    "Wereboar",
    "Wererat",
    "Werewolf",
    "Wight",
    "Wolf",
    "Worg",
    "Wraith",
    "Wyvern",
    "Xorn",
    "Yeti",
    "Zombie"
];

export const getRandomName = () => {
    return monsters[Math.floor(Math.random() * monsters.length)];
}

export default monsters;
