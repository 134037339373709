import { useSelector, useDispatch } from "react-redux";
import { useRef } from "react";

import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
    selectEditModalOpen,
    selectEditItem,
    setModalOpen
} from "./editableSlice";
import { updateItem } from "../bag/bagSlice";


const EditableModal = () => {
    const dispatch = useDispatch();
    const showModal = useSelector(selectEditModalOpen);

    const nameRef = useRef(null);
    const quantityRef = useRef(null);
    const descriptionRef = useRef(null);

    const item = useSelector(selectEditItem);

    const handleClose = () => dispatch(setModalOpen({ open: false }));
    const handleSave = () => {
        handleClose();
        dispatch(updateItem({
            item: {
                ...item,
                name: nameRef.current.value,
                quantity: Number(quantityRef.current.value),
                description: descriptionRef.current.value
            }
        }));
    }

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="text-truncate">
                    {/* TODO: keep this constant */}
                    Edit {item ? item.name : "NULL"}
                </Modal.Title>
            </Modal.Header>
            {item ?
                <>
                    <Modal.Body>
                        <Row>
                            <Col xs="auto">
                                <Row>
                                    <InputGroup.Text id="em-name" className="text-end">Name</InputGroup.Text>
                                </Row>
                                <Row>
                                    <InputGroup.Text id="em-quant">Quantity</InputGroup.Text>
                                </Row>
                                <Row>
                                    <InputGroup.Text id="em-desc">Description</InputGroup.Text>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <FormControl
                                        placeholder="Item Name"
                                        defaultValue={item.name}
                                        ref={nameRef}
                                        aria-describedby="em-name"
                                    />
                                </Row>
                                <Row>
                                    <FormControl
                                        placeholder="Quantity"
                                        defaultValue={item.quantity}
                                        type="number"
                                        min="1"
                                        ref={quantityRef}
                                        aria-describedby="em-quant"
                                    />
                                </Row>
                                <Row>
                                    <FormControl
                                        placeholder="Description"
                                        defaultValue={item.description}
                                        as="textarea"
                                        ref={descriptionRef}
                                        aria-describedby="em-desc"
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </>
                :
                <>
                    <p>Item is null</p>
                </>
            }

        </Modal>
    );
}

export default EditableModal;