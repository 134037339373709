import React from "react";

function Icon({width="2048", height="2048"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 2048 2048"
    >
      <defs>
        <clipPath id="clipPath1056">
          <path
            fill="#00f"
            fillRule="evenodd"
            strokeWidth="1.273"
            d="M128.85 443.01h1324.4v2158.3H128.85zm-355.61 1072.9v1269.6h2274.8v-1269.6z"
            className="powerclip"
            display="block"
          ></path>
        </clipPath>
      </defs>
      <g stroke="#000" strokeWidth="50">
        <rect
          width="1256.2"
          height="1256.2"
          x="-1760"
          y="-1984"
          fill="#803300"
          fillRule="evenodd"
          rx="314.06"
          ry="314.06"
          transform="scale(-1)"
        ></rect>
        <path
          fill="#502d16"
          d="M817.81 727.75H896.3259999999999V1984.05H817.81z"
        ></path>
        <path
          fill="#502d16"
          d="M1367.4 727.75H1445.9160000000002V1984.05H1367.4z"
        ></path>
        <rect
          width="1256.3"
          height="628.13"
          x="503.75"
          y="727.75"
          fill="#28170b"
          rx="314.06"
          ry="314.06"
        ></rect>
      </g>
      <g
        strokeWidth="40.756"
        clipPath="url(#clipPath1056)"
        transform="translate(-124.38 -528.5) scale(1.2268)"
      >
        <g stroke="#000">
          <g
            fill="#666"
            strokeLinejoin="round"
            strokeWidth="18.794"
            transform="translate(32 -32) matrix(2.0601 -.55693 .58044 2.1258 -99.24 -531.57)"
          >
            <path d="M134.69 618.7h64v864h-64z"></path>
            <g transform="translate(6.69 10.702)">
              <path d="M192 736s96 0 96 96c0 0 128 0 128-128 0 0 0-128-128-128 0 0 0 96-96 96v64-64"></path>
            </g>
            <path d="M134.69 746.7s-96 0-96 96c0 0-128 0-128-128 0 0 0-128 128-128 0 0 0 96 96 96v64-64"></path>
          </g>
          <path
            fill="gray"
            d="M942.11 729.15s10.226-67.263 80.489-57.258l140.53 20.011s70.263 10.005 60.037 77.269c0 0-70.263-10.005-80.489 57.258l-20.451 134.53s-10.226 67.264 60.037 77.269l210.79 30.016s-10.226 67.264-80.489 57.258l-140.53-20.011-173.84 1143.5-171.2 181.78-109.85-221.8 173.84-1143.5-140.53-20.011s-70.263-10.005-60.038-77.269l210.79 30.016s70.263 10.005 80.489-57.258l20.451-134.53s10.226-67.263-60.038-77.269"
            transform="translate(32 -32)"
          ></path>
          <g fill="#b3b3b3" strokeWidth="17.089">
            <path
              d="M1408 544c0 192 64 256 64 256v32c-84.309-58.771-96-288-96-288s0-96 32-192c0 0 32-96 64-128v32s-64 128-64 288zM1472 800V256"
              transform="translate(32 -32) matrix(-1.6122 -.40238 -.79088 3.3307 4005.6 -29.765) translate(1.06 128.67)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
