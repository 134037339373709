import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheck from "react-bootstrap/FormCheck";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../features/user/userSlice";
import { USER } from "../utils/models";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/api";
import Spinner from "../components/Spinner";
import { addToast } from "../features/toasts/toastsSlice";
import { v4 as uuidv4 } from "uuid";
import { useKeycloak } from "@react-keycloak/web";

const Me = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const username = user && user[USER.username.value];
    const email = user && user[USER.email.value];
    const subscribed = user && user[USER.subscribed.value];
    const myBags = (user && user[USER.owned_bags.value]) || [];
    const [pending, setPending] = useState(false);
    const [userPending, setUserPending] = useState(true);
    const { keycloak } = useKeycloak();
    const isSignedIn = keycloak.authenticated;
    const userToken = keycloak.tokenParsed;

    useEffect(() => {
        if (isSignedIn) {
            setUserPending(true);
            api.user.me().then(user => {
                dispatch(setUser({ user }));
                setUserPending(false);
            }).catch(err => {
                dispatch(addToast({
                    toast: {
                        header: "Error loading user details",
                        content: "Please refresh the page",
                        type: "error",
                        key: uuidv4()
                    }
                }));
                setUserPending(false);
            })
        }
    }, [dispatch, isSignedIn]);

    return (
        <>
            <Row>
                <Col className="text-center">
                    <h3>{username} user page</h3>
                </Col>
            </Row>
            {userPending
                ? <Row><Col className="text-center"><Spinner /></Col></Row>
                : <>
                    <Row>
                        <Col className="text-end">Username:</Col>
                        <Col>{username}</Col>
                    </Row>
                    <Row>
                        <Col className="text-end">Email:</Col>
                        <Col>{email}</Col>
                    </Row>
                    <Row>
                        <Col className="text-end">Subscribed to emails:</Col>
                        <Col>
                            <FormCheck
                                label={pending
                                    ? <Spinner />
                                    : subscribed ? "Yes" : "No"
                                }
                                checked={subscribed}
                                disabled={pending}
                                onChange={e => {
                                    setPending(true);
                                    api.user.subscription(!e.target.checked).then(() => {
                                        dispatch(setUser({
                                            user: {
                                                ...user,
                                                [USER.subscribed.value]: !e.target.checked
                                            }
                                        }))
                                        setPending(false);
                                    }).catch(({ error, status }) => {
                                        // not gonna do much here
                                        setPending(false);
                                        dispatch(addToast({
                                            toast: {
                                                header: "Error updating subscription preferences",
                                                content: "Please contact support",
                                                type: "error",
                                                key: uuidv4()
                                            }
                                        }))
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="text-center">
                            <h3>My Bags</h3>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-evenly">
                        {myBags.length > 0
                            ? myBags.map(bag =>
                                <Col xs="4" className="fw-bold text-center fs-4">
                                    <Link to={`/bag/${bag}`}>
                                        {bag}
                                    </Link>
                                </Col>
                            )
                            : <Col xs="auto">
                                *crickets*... looks like you need to get into the field and <Link to="/">make a bag</Link>, adventurer!
                            </Col>
                        }
                    </Row>
                </>
            }
        </>
    );
};

export default Me;