import { useState, cloneElement, useMemo } from "react";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
//import Button from "react-bootstrap/Button";

import FilterIcon from "@mui/icons-material/FilterList";

const DEFAULT_PAGE_SIZE = 1000;
//const PAGE_SIZE_INC = 30;

const ListQuery = ({ children, items }) => {
    const [query, setQuery] = useState("");
    const [sliceMax, setSliceMax] = useState(DEFAULT_PAGE_SIZE);
    const filteredItems = useMemo(() => {
        return items ? items.filter(i => {
            return (query === "" || i.name.toLowerCase().includes(query.toLowerCase()))
        }) : []
    }, [query, items]);
    const paginatedItems = useMemo(() => {
        return filteredItems.slice(0, sliceMax);
    }, [filteredItems, sliceMax]);
    //const moreToShow = paginatedItems.length < filteredItems.length;
    //const paginatedItemsWithButton = useMemo(() => {
    //    const button = <Button variant="link" onClick={() => setSliceMax(sliceMax + PAGE_SIZE_INC)}>Show more items</Button>;
    //    return moreToShow ? [...paginatedItems, button] : paginatedItems;
    //}, [moreToShow, paginatedItems, sliceMax, PAGE_SIZE_INC]);

    return (
        <>
            <InputGroup style={{ marginBottom: "5px" }}>
                <InputGroup.Text>
                    <FilterIcon />
                </InputGroup.Text>
                <FormControl
                    placeholder="Filter Items"
                    onChange={e => setQuery(e.target.value)}
                />
            </InputGroup>
            {cloneElement(children, {
                children: paginatedItems
            })}
        </>
    )
}

export default ListQuery;