import { useDispatch, useSelector } from "react-redux";

import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import CasinoIcon from "@mui/icons-material/Casino";
import PersonIcon from "@mui/icons-material/Person"

import { resetBag } from "../features/bag/bagSlice";
import { selectNickname, setCachedPassword, setCurrentCode, setPasswordFor, setRandomNickname } from "../features/auth/authSlice";
import Logo from "../logo.jsx";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";


const TopNav = () => {
    const dispatch = useDispatch();
    const nickname = useSelector(selectNickname);
    const { keycloak } = useKeycloak();

    return (
        <Navbar style={{ marginBottom: "10px" }} bg="secondary" expand="lg">
            <Container>
                <Row className="w-100 d-flex align-items-center">
                    <Col>
                        <LinkContainer to="/">
                            <Navbar.Brand
                                onClick={e => {
                                    dispatch(resetBag());
                                    dispatch(setCurrentCode({ currentCode: null }));
                                    dispatch(setCachedPassword({ cachedPassword: null }));
                                    dispatch(setPasswordFor({ cachedPasswordFor: null }));
                                }}
                                className="me-1"
                            >
                                <Logo width="64" height="64" />
                                <div className="d-inline-block mt-auto">
                                    Extradimensional
                                </div>
                            </Navbar.Brand>
                        </LinkContainer>
                    </Col>
                    <Col xs="auto">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    </Col>
                    <Col lg="auto" xs="12">
                        <Navbar.Collapse className="justify-content-end">
                            <Row className="py-2">
                                {!keycloak.authenticated
                                    ? <>
                                        <Col xs="auto" lg>
                                            <Button type="button" variant="primary"
                                                onClick={() => keycloak.login()}
                                            >
                                                Login
                                            </Button>
                                        </Col>
                                        <Col className="d-flex align-items-start">
                                            <Button type="button" variant="success"
                                                onClick={() => keycloak.register()}
                                            >
                                                Register
                                            </Button>
                                        </Col>
                                    </>
                                    : <>
                                        <Col xs="auto" className="gx-4 d-flex align-items-center">
                                            <PersonIcon />
                                            <Link to="/me">{keycloak.tokenParsed?.preferred_username || "UNDEFINED"}</Link>
                                        </Col>
                                        <Col xs="auto" className="gx-1 d-flex align-items-center">
                                            <Button type="button" variant="dark"
                                                onClick={() => keycloak.logout()}
                                            >
                                                logout
                                            </Button>
                                        </Col>
                                    </>
                                }
                                <Col xs="auto" className="d-flex align-items-center">
                                    {!keycloak.authenticated && <Form className="d-flex" onSubmit={() => { }}>
                                        <Form.Label className="mx-1 my-auto h-100">
                                            Nickname
                                        </Form.Label>
                                        {/*
                                        <Form.Control
                                            type="text"
                                            placeholder="Set Nickname"
                                            defaultValue={nickname}
                                            isInvalid={!validNickname}
                                            maxLength="32"
                                            size="sm"
                                            onChange={e => {
                                                dispatch(setNickname({ nickname: e.target.value.trim() }));
                                            }}
                                            value={nickname}
                                            disabled
                                        />*/}
                                        <Form.Label className="h-100 mx-1 my-auto bg-light p-1">
                                            {nickname}
                                        </Form.Label>
                                        <span className="text-secondary d-flex align-items-center hover-spin" role="button" onClick={() => {
                                            dispatch(setRandomNickname())
                                        }}><CasinoIcon /></span>
                                    </Form>
                                    }
                                </Col>
                            </Row>
                        </Navbar.Collapse>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    )
}

export default TopNav;
